.navbar{
    height: 50px;
    border-bottom: 0.5px solid rgb(231,228,228) ;
    display:flex;
    align-items: center;
    font-size: 14px;
    color: #555;

    .wrapper{
     display:flex;
     align-items: center;
     padding: 20px;
     justify-content: space-between;
     width: 100%;

        .items{
            display: flex;
            align-items: center;

            .item{
                display: flex;
                align-items: center;
                margin-right: 50px;

                .icon{
                    font-size: 20px;
                }
            }
        }
        .search{
            display: flex;
            align-items: center;
            // border: 0.5px solid lightgray;
            padding: 3px;

            input{
                border: none;
                outline: none;
                background-color: transparent;

                &::placeholder{
                  font-size: 12px;  
                }
            }
        }
    }
}