.chart{
    flex: 4;
    color: gray;

    .title{
        margin-bottom: 15px;
    }

    .chartGrid{
        stroke: rgb(224, 221, 221)
    }

    .listContainer{
        padding: 20px;
        margin: 20px;
    }
}