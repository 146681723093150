.featured{
    flex: 2;
    // padding: 10px;

    .top{
        display: flex;
        align-items: center;
        justify-content: space-between ;
        color: gray;

        .title{
            font-size: 16px;
            font-weight: 500;
        }
    }

    .bottom{
        padding: 20px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 15px;

        .featuredChart{
            width: 100px;
            height: 100px ;
        }

        .title{
            font-weight: 500;
            color: gray; 
        }
        .amount{
            font-size: 30px;
        }
        .desc{
            font-weight: 300;
            font-size: 12px;
            color: gray;
            text-align: center;
        }

        .summary{
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;

            .item{
                text-align: center;

                .itemTitle{
                    font-size: 14px;
                    color: gray;
                }
                .itemResult{
                    display: flex;
                    align-items: center;
                    margin-top: 10px;
                    font-size: 14px;

                    &.positive{
                       color: green; 
                    }
                    &.negative{
                        color: red; 
                     }

                }
            }
        }
    }
}