.pl{
    padding: 0px 100px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.pl-text{
    width: 65%;
}

.pl-title{
    font-size: 50px;
    font-weight: 600;
    margin-top: 50px;
}

.pl-desc{
    margin: 20px 0px;
}

.pl-list{
    width: 100vmax;
    display: flex;
    flex-wrap: wrap;
    justify-content:space-evenly;
    
    /* padding-left: 10px; */
    /* border: #EB9F34 2px solid; */
}

@media screen and (max-width: 480px){
    .pl{
        padding: 200px 0px;
        margin-top: 42vh;
    }

    .pl-text{
        width: 100%;
    }

    .pl-desc{
        display: none;
    }
    .pl-list{
        /* align-items:center; */
        display: block;
        width: 80%;
        /* flex-direction: row; */
        
    }
    .pl-title{
        margin-top: 120px;
    }
}