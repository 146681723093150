.widget{
    // display: flex;
    justify-content: space-between;
    flex: 1;
    padding: 10px;
    

    .left, .right{
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .title{
            font-weight: bold;
            font-size: 14px;
            color: rgb(160, 160, 160)
        }

        .counter{
            font-size: 28px;
            font-weight: 300;
        }

        .link{
            font-size: 12px;
            border-bottom: 1px solid gray;
        }

        .percentage{
            display: flex;
            align-items: center;
            font-size: 14px;

            &.positive{
                color: green;
            }
            &.negative{
                color: red;
            }
        }
        .icon{
            font-size: 18px;
            padding: 5px;
            
            border-radius: 5px;
            align-self: flex-end;
        }
    }

} 