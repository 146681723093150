.t{
    pointer-events: initial;
    position: relative;    
    left: 10px;
    z-index: 50;
    /* margin-right: 30px; */
    display: flex;
    /* align-items: left;     */
    /* justify-content: space-around; */
    /*top: 10px;*/
}