.sidebar{
    flex: 1;
    border-right: 0.5px solid lightgray; 
    min-height: 100vh; 
    // background-color: white;

    .top{
        height: 42px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: row;

        .logo{
            font-size: 20px;
            font-weight: bold;
            color: #FF7043
        }
    }

    hr {
      border: 0.5px solid lightgray;  
      height: 0;
      
    }

    .center{
        padding-left: 20px;

        ul{
            list-style: none;
            margin: 0;
            padding: 0;

            .title{
                font-size: 10px;
                font-weight: bold;
                color: #999;
                margin-top: 15px;
                margin-bottom: 5px;
            }

            li{
                display: flex;
                align-items: center;
                padding: 5px;
                cursor: pointer;

                &:hover{
                    background-color: #ece8ff ;
                }

                .icon{
                    font-size: 18px;
                    color: #3480eb
                }

                span{
                    font-size: 13px;
                    font-weight: 600;
                    color: #888;
                    margin-left: 10px;
                }
            }

        }

    }

    .bottom{
        display: flex;
        align-items: center;
        margin: 10px;

        .colorOption{
            width: 20px;
            height: 20px;
            border-radius: 5px;
            border: 1px solid #7451f8;
            cursor:pointer;
            margin: 5px;

            &:nth-child(1){
                background-color: whitesmoke;
            }
            &:nth-child(2){
                background-color: #333;
            }
            &:nth-child(3){
                background-color: darkblue ;
            }
        }
    }

}