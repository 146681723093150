.description{
    height: 100vh;
    display: flex;
    align-items: center;
    margin-bottom: 30px;
    /* margin-top: 60px; */
}

.description-left{
    flex:1;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    height: 100%;
}

.description-right{
    flex:1;
    margin-top: 100px;
}

.description-card.bg{
    position: absolute;
    top: 50px;
    left: 50px;
    background-color: #3480eb
}

.description-card{
    width: 60%;
    height: 70vh;
    border-radius: 30px;
    position: absolute;
    overflow: hidden;
}

.description-image{
    width: 100%;
    height: 100%;
    object-fit:cover;
    border-radius: 20px;
    /* margin-top: 50px; */
}

.description-award-image{
    height: 250px;
    width: 250px;
    border-radius: 20px;
    object-fit: contain;
    bottom: 100px;
    
}
.mobile-image{
    height: 250px;
    width: 250px;
    object-fit: contain;
    /* position: absolute; */
}

.description-title{
    font-weight: 400;
}

.description-subtitle{
    margin: 20px 0px 1px;
}

.description-description{
    font-weight: 300;
}

.description-award{
    margin-top: 50px;    
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.description-award-finalone{
    margin-top: 50px;    
    display: flex;
    align-items: center;
    justify-content: space-between;

}

.description-award-text{
    width: 70%;
}

.description-award-title{
    font-weight: bold;
    color:darkgray;
    margin-bottom: 10px;
    
}

@media screen and (max-width: 480px) {
    .description{
        flex-direction: column;
        text-align: center;
        margin-top:80px;
    }

    .description-left{
        width: 100%;
    }
    
    .description-card{
        height: 30vh;
    }
    
    .description-card.bg{
        display: none;
    }

    .description-right{
        padding: 20px;
    }
    .description-award{
        display: block;
    }
    .description-award-finalone{
        display: block;
        
    }
}